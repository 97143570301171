// Import Vue
import Vue from 'vue'
import VueRouter from 'vue-router'

import PayoutRoot from '@/Root.vue'
import RootPage from '@/RootPage.vue'
import ByUuidExPage from '@/ByUuidExPage.vue'
import ByUuidPage from '@/ByUuidPage.vue'


Vue.use(VueRouter)


let routes=[
    {
        path: '/',
        name: 'Root',
        component: RootPage,
    },
    {
        path: '/byuuid-ex',
        name: 'ByUuidEx',
        component: ByUuidExPage,
    },
    {
        path: '/byuuid',
        name: 'ByUuid',
        component: ByUuidPage,
    }

];

// Configure router
const router = new VueRouter({
    routes,
    linkActiveClass: 'active',
    mode: 'hash'
});

new Vue({
    el: '#payout',
    render: h => h(PayoutRoot),
    router,
})
