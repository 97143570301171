var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", [_vm._v("ByUuidExPage.veu")]),
    _vm._v(" "),
    _setup.wait() ? _c("div", [_vm._v("wait...")]) : _vm._e(),
    _vm._v(" "),
    !_setup.wait() && !_setup.ok()
      ? _c("div", [_vm._v(_vm._s(_setup.err()))])
      : _vm._e(),
    _vm._v(" "),
    _setup.ok()
      ? _c(
          "div",
          [
            _c("div", [_vm._v("claimNum=" + _vm._s(_setup.payout().claimNum))]),
            _vm._v(" "),
            _c("div", [_vm._v("status=" + _vm._s(_setup.payout().status))]),
            _vm._v(" "),
            _c("div", [
              _vm._v("ProviderId=" + _vm._s(_setup.payout().providerId)),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "ProviderAgentId=" + _vm._s(_setup.payout().providerAgentId)
              ),
            ]),
            _vm._v(" "),
            _c("div", [_vm._v("Заявка")]),
            _vm._v(" "),
            _c("div", [_vm._v("туристы")]),
            _vm._v(" "),
            _vm._l(_setup.claim().tourists, function (t) {
              return _c("div", [
                _vm._v("\n            " + _vm._s(t) + "\n        "),
              ])
            }),
            _vm._v(" "),
            _c("div", [_vm._v("Телефон " + _vm._s(_setup.account().phone))]),
            _vm._v(" "),
            _c("div", [
              _c("button", { on: { click: _setup.cli_send } }, [
                _vm._v("отправить"),
              ]),
            ]),
            _vm._v(" "),
            _setup.payob.send.ok && _setup.payob.send.resolve.ticket
              ? _c("div", [
                  _vm._v("смс код\n            "),
                  _c("input", {
                    staticClass: "confirm-form__input",
                    attrs: {
                      type: "number",
                      name: "sms-code",
                      maxlength: _setup.maxlength,
                      required: "",
                    },
                    domProps: { value: _setup.code },
                    on: { input: _setup.set_code },
                  }),
                ])
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }