<template><div class="confirm">

    <div class="confirm-h">Подтвердите согласие на получение выплаты</div>

    <div v-if="!send.ok">
        <button class="btn"  @click="doSend" :disabled="send.wait">
            Отправить СМС на {{ phone_mask(props.phone) }} 
            <span v-if="send.wait"><Loader class="confirm-loa" /></span>
        </button>
    </div>

    <div v-if="send.ok && !tm.timer_leave">

        <Warn>Верный код не получен</Warn>

        <button class="btn"  @click="doSend" :disabled="send.wait">
            Повторить отправку СМС 
            <span v-if="send.wait"><Loader class="confirm-loa1" /></span>
        </button>
    </div>

    <Warn v-if="epoch && !send.wait && !send.ok">СМС не отправлено. Попробуйте еще раз</Warn>

    <div v-if="send.ok && tm.timer_leave">
        <Success>
            На номер {{ phone_mask(props.phone)  }} отправлено СМС с кодом подтверждения выплаты.
            <br>Отправить код повторно можно будет через 
                <span>{{second(tm.timer_leave)}} сек.</span>
        </Success>

        <div class="confim-big">
            <div class="confim-big-l">Введите код</div>
            <div class="confim-big-el">
                <Loader v-if="confirm.wait" class="confirm-loa1" />
                <input v-else
                  type="number"
                  class="confirm-input"
                  :value="code"
                  @input="set_code"
                  :maxlength="maxlength"
                  :disabled="confirm.wait"
                  placeholder="9999"
                  required></div>
        </div>

        <Warn v-if="confirm.ok &&  !confirm.resolve.success">
            код неверный, попробуйте еще раз
        </Warn>

        <Warn v-if="confirm.reject">отправить не удалось: {{confirm.reject}}</Warn>
    </div>




</div></template>

<style lang="scss">
    .confirm-loa {height: 20px;}
    .confirm-loa1 {height: 40px;}
    .confim-big {
        margin: 20px 0;
        display: flex;
        align-items: center;
        &-l {font-size: 20px;  padding-right: 20px;}

    }
    .confirm-input {
        width: 120px;
        border: 1px solid #00f;
        font-size: 1rem;
        line-height: 1.62;
        padding: 6px 12px;
        box-shadow: none;
        background-color: #fff;
        border-radius: 5px;
        margin: 5px 0;
        color: #000;
        transition: .25s ease box-shadow;
        text-align: center;
        letter-spacing: 14px;
        font-size: 20px;
        appearance: textfield;
    }
    .confirm-h {
        font-weight: bold;
        margin-bottom: 20px;
    }
</style>

<script setup>
import {ref, unref, watch, computed, onUnmounted} from 'vue'
import {payoutSendCode, payoutConfirm} from '@/actions.js'
import {fetch_save} from '@/util.js'
import Success from '@/comp/Success.vue'
import Loader from '@/comp/Loader/Loader.vue'
import Warn from '@/comp/Warn.vue'


const props = defineProps({
    payoutId: String, 
    providerId: String, 
    providerAgentId: String,
    phone: String,
})

const emits = defineEmits([ 'refresh' ])

const send = ref(fetch_save({wait: false}))
const confirm = ref(fetch_save({wait: false}))
const code = ref('')
const maxlength = 4

const tm = ref({
    expires: -1,
    expires_start: -1,
    timer_leave: 0,
    timer: null,
    attempt: 0,
})



const epoch = ref(0)


const second = ms => Math.ceil(ms / 1000)

const phone_mask = (tel) => {
    return tel.replace(/^(..).+(....)$/, '$1 *** **** $2')
}


const set_code = (event) => {
    let thisValue = event.target.value.trim()
    if (thisValue.length > maxlength) {
        thisValue = thisValue.slice(0, maxlength)
    }
    code.value = thisValue
    if (thisValue.length === maxlength) {
        doConfirm()
    }
}



const doSend = () => {
    if (unref(send).wait) {// страховка
        return
    }
    epoch.value = epoch.value + 1 // отсекаем ненужные confirm
    send.value = fetch_save({wait: true})
    confirm.value  = fetch_save({wait: false})
    code.value = ''
    stop_timer() // перестраховка, если где-то что-то пропустил

    let {payoutId, providerAgentId}  = props

    payoutSendCode( { payoutId, providerAgentId } ).then( resolve => {
        send.value = fetch_save({resolve})
        let {expires, attempt} = resolve
        start_timer( expires, attempt )
    }, reject => {
        send.value = fetch_save({reject})
    })
}

const doConfirm = () => {
    if (unref(confirm).wait || !unref(send).ok || !unref(tm).timer_leave) {
        return
    }
    let {providerId } = props
    let {ticket} = unref(send).resolve
    let codeval = unref( code )
    const epoch_on = unref(epoch)
    confirm.value  = fetch_save({wait: true})

    payoutConfirm( { providerId, ticket, code: codeval   } ).then( resolve => {
        if (epoch.value === epoch_on) {// игнорим запоздалый confirm
            let {attempt, success} = resolve
            confirm.value = fetch_save({resolve})
            attempt_timer( attempt )
            if (success) {
                emits('refresh')
            } else {
                code.value = ''
            }
        }
    }, reject => {
        if (epoch.value === epoch_on) {// игнорим запоздалый confirm
            confirm.value = fetch_save({reject})
        }
    })
}

const start_timer = (expires, attempt)=>{
    let tmset = tm.value
    tmset.expires = expires
    tmset.expires_start = Date.now()
    tmset.attempt = attempt
    tick_timer()
}
const stop_timer = () => {
    if (tm.value.timer) {
        clearTimeout(tm.value.timer)
    }
    tm.value = {
        expires: -1,
        expires_start: -1,
        timer_leave: 0,
        timer: null,
        attempt: 0,
    }
}
const attempt_timer = (attempt) => {
    let tmset = tm.value
    tmset.attempt = attempt
    if (0 == attempt) {
        stop_timer()
    }
}
const tick_timer = () => {
    let now = Date.now()
    let tmset = tm.value
    const timer_leave = tmset.expires_start + tmset.expires * 1000 - now
    if (timer_leave <= 0) {
        stop_timer()
    } else {
        tmset.timer_leave = timer_leave
        tmset.timer = setTimeout( tick_timer, 1000)
    }
}

onUnmounted(() => {
    stop_timer()
})




</script>