<template>
    <div>
        <Payout v-if="payoutId" :payoutId="payoutId" :key="`${payoutId}.${epoch}`" @refresh="refresh" />

        <Warn v-if="!payoutId">
            Неверная ссылка: не задан код выплаты
        </Warn>
    </div>
</template>

<script setup>
import {ref, unref, watch, computed} from 'vue'
import { useRoute } from 'vue-router/composables'
import Payout from '@/comp/Payout.vue'
import FailSvg from '@/comp/svg/fail.vue'
import OkSvg from '@/comp/svg/ok.vue'
import Warn from '@/comp/Warn.vue'

const payoutId = ref('')
const epoch = ref(0) // обновляем вид, если что-то изменилось

const refresh = () => {
    epoch.value = epoch.value + 1
}


const route = useRoute()

watch( 
    () => route.query.payoutId,
    () => {
        let { payoutId:id } = route.query
        payoutId.value = id ? id.toString() : '' // всегда строка(!)
        epoch.value = epoch.value + 1
    },
    { immediate: true }
)
</script>
