var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "confirm" },
    [
      _c("div", { staticClass: "confirm-h" }, [
        _vm._v("Подтвердите согласие на получение выплаты"),
      ]),
      _vm._v(" "),
      !_setup.send.ok
        ? _c("div", [
            _c(
              "button",
              {
                staticClass: "btn",
                attrs: { disabled: _setup.send.wait },
                on: { click: _setup.doSend },
              },
              [
                _vm._v(
                  "\n            Отправить СМС на " +
                    _vm._s(_setup.phone_mask(_setup.props.phone)) +
                    " \n            "
                ),
                _setup.send.wait
                  ? _c(
                      "span",
                      [_c(_setup.Loader, { staticClass: "confirm-loa" })],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _setup.send.ok && !_setup.tm.timer_leave
        ? _c(
            "div",
            [
              _c(_setup.Warn, [_vm._v("Верный код не получен")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn",
                  attrs: { disabled: _setup.send.wait },
                  on: { click: _setup.doSend },
                },
                [
                  _vm._v("\n            Повторить отправку СМС \n            "),
                  _setup.send.wait
                    ? _c(
                        "span",
                        [_c(_setup.Loader, { staticClass: "confirm-loa1" })],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _setup.epoch && !_setup.send.wait && !_setup.send.ok
        ? _c(_setup.Warn, [_vm._v("СМС не отправлено. Попробуйте еще раз")])
        : _vm._e(),
      _vm._v(" "),
      _setup.send.ok && _setup.tm.timer_leave
        ? _c(
            "div",
            [
              _c(_setup.Success, [
                _vm._v(
                  "\n            На номер " +
                    _vm._s(_setup.phone_mask(_setup.props.phone)) +
                    " отправлено СМС с кодом подтверждения выплаты.\n            "
                ),
                _c("br"),
                _vm._v(
                  "Отправить код повторно можно будет через \n                "
                ),
                _c("span", [
                  _vm._v(
                    _vm._s(_setup.second(_setup.tm.timer_leave)) + " сек."
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "confim-big" }, [
                _c("div", { staticClass: "confim-big-l" }, [
                  _vm._v("Введите код"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "confim-big-el" },
                  [
                    _setup.confirm.wait
                      ? _c(_setup.Loader, { staticClass: "confirm-loa1" })
                      : _c("input", {
                          staticClass: "confirm-input",
                          attrs: {
                            type: "number",
                            maxlength: _setup.maxlength,
                            disabled: _setup.confirm.wait,
                            placeholder: "9999",
                            required: "",
                          },
                          domProps: { value: _setup.code },
                          on: { input: _setup.set_code },
                        }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _setup.confirm.ok && !_setup.confirm.resolve.success
                ? _c(_setup.Warn, [
                    _vm._v(
                      "\n            код неверный, попробуйте еще раз\n        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _setup.confirm.reject
                ? _c(_setup.Warn, [
                    _vm._v(
                      "отправить не удалось: " + _vm._s(_setup.confirm.reject)
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }