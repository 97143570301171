var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("footer", { staticClass: "wait-css footer" }, [
      _c("div", { staticClass: "footer__wrap container" }, [
        _c("div", { staticClass: "footer__nav" }, [
          _c("div", { staticClass: "footer__nav__col" }, [
            _c("p", { staticClass: "footer__nav__title footer__col-title" }, [
              _vm._v("ЭТО сервисы"),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footer__nav__link",
                attrs: {
                  href: "https://eto.travel/search/",
                  title: "Поиск туров",
                },
              },
              [_vm._v("Поиск туров")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footer__nav__link",
                attrs: {
                  href: "https://eto.travel/avia/",
                  title: "Поиск, бронирование и выписка авиабилетов",
                },
              },
              [_vm._v("Авиабилеты")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footer__nav__link",
                attrs: {
                  href: "https://eto.travel/workspace/insurance/",
                  title: "Online страхование",
                },
              },
              [_vm._v("Страхование")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footer__nav__link",
                attrs: { href: "https://eto.travel/touristpage/" },
              },
              [_vm._v("Страница туриста")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footer__nav__link",
                attrs: { href: "https://eto.travel/pokupay/" },
              },
              [_vm._v("Покупай со Сбером")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "footer__nav__col" }, [
            _c("p", { staticClass: "footer__nav__title footer__col-title" }, [
              _vm._v("\n          ЭТО мы\n        "),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footer__nav__link",
                attrs: { href: "https://eto.travel/about/", title: "О нас" },
              },
              [_vm._v("О нас")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footer__nav__link",
                attrs: {
                  href: "https://eto.travel/about/terms/",
                  title: "Условия сотрудничества",
                },
              },
              [_vm._v("Для кого")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footer__nav__link",
                attrs: { href: "https://eto.travel/help/registration/" },
              },
              [_vm._v("Справочный центр")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footer__nav__link",
                attrs: {
                  href: "https://eto.travel/customer/",
                  title: "Туристам",
                },
              },
              [_vm._v("Туристам")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footer__nav__link",
                attrs: {
                  href: "https://eto.travel/about/kontaktnaya-informaciya/",
                  title: "Контактная информация",
                },
              },
              [_vm._v("Контакты")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "footer__more" }, [
          _c("div", { staticClass: "footer__media media" }, [
            _c("p", { staticClass: "media__title footer__col-title" }, [
              _vm._v("\n          Мы в социальных сетях\n        "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "media__list" }, [
              _c("div", { staticClass: "media__item" }, [
                _c(
                  "a",
                  {
                    staticClass: "media-link",
                    attrs: { target: "_blank", href: "https://t.me/ETOTRAVEL" },
                  },
                  [
                    _c("img", {
                      staticClass: "media__icon",
                      attrs: {
                        src: "https://cdn.eto.travel/wp-content/themes/t01/images/social/telegram.svg",
                        alt: "Подпишись Telegram",
                      },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "media__item" }, [
                _c(
                  "a",
                  {
                    staticClass: "media-link",
                    attrs: {
                      target: "_blank",
                      href: "https://vk.com/public203635095",
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "media__icon",
                      attrs: {
                        src: "https://cdn.eto.travel/wp-content/themes/t01/images/social/vk.svg",
                        alt: "Подпишись ВКонтакте",
                      },
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "footer__payments payments" }, [
            _c("p", { staticClass: "payments__title footer__col-title" }, [
              _vm._v("\n          Мы принимаем к оплате\n        "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "payments__list" }, [
              _c("div", { staticClass: "payments__item" }, [
                _c("span", {
                  staticStyle: {
                    "background-image":
                      "url(https://cdn.eto.travel/i/cards/visa.svg)",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "payments__item" }, [
                _c("span", {
                  staticStyle: {
                    "background-image":
                      "url(https://cdn.eto.travel/i/cards/master.svg)",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "payments__item" }, [
                _c("span", {
                  staticStyle: {
                    "background-image":
                      "url(https://cdn.eto.travel/i/cards/mir.svg)",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "payments__item" }, [
                _c("span", {
                  staticStyle: {
                    "background-image":
                      "url(https://cdn.eto.travel/i/cards/ya_pay.svg)",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "payments__item" }, [
                _c("span", {
                  staticStyle: {
                    "background-image":
                      "url(https://cdn.eto.travel/i/cards/sfp.svg)",
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "payments__item",
                  staticStyle: { width: "70px" },
                },
                [
                  _c("span", {
                    staticStyle: {
                      "background-image":
                        "url(https://cdn.eto.travel/i/sber-credit/footer.svg)",
                      width: "70px",
                    },
                  }),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container footer__line" }, [
        _c("div", { staticClass: "footer__text-box" }, [
          _c("p", [
            _vm._v(
              "\n        Все используемые визуальные материалы являются собственностью ЭТО трэвел, используются с согласия правообладателя в рамках заключенных договоров, либо получены из открытых источников.\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              " \n        Сайт использует файлы «cookie», с целью персонализации сервисов и повышения удобства пользования веб-сайтом. «Cookie» представляют собой небольшие файлы, содержащие информацию о предыдущих посещениях веб-сайта. Если вы не хотите использовать файлы «cookie», измените настройки браузера.\n      "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container footer__line" }, [
        _c("div", { staticClass: "footer__text-box" }, [
          _c("p", [
            _vm._v(
              "\n      © 2013-2024. Все права защищены. ЭТО трэвел.\n      "
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }