var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _setup.wait() ? _c("div", [_c(_setup.Loader)], 1) : _vm._e(),
      _vm._v(" "),
      !_setup.wait() && !_setup.ok()
        ? _c(_setup.Warn, [_vm._v(_vm._s(_setup.err()))])
        : _vm._e(),
      _vm._v(" "),
      _setup.ok()
        ? _c(
            "div",
            [
              _c("h1", [
                _vm._v("Выплата по заявке " + _vm._s(_setup.payout().claimNum)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "g" }, [
                _c("div", { staticClass: "g-line" }, [
                  _c("div", { staticClass: "g-line-t" }, [_vm._v("Период")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "g-line-d" }, [
                    _vm._v(
                      _vm._s(_setup.dayjs_dm(_setup.claim().dateBeg)) +
                        " - " +
                        _vm._s(_setup.dayjs_dm(_setup.claim().dateEnd))
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "g-line" }, [
                  _c("div", { staticClass: "g-line-t" }, [_vm._v("Страна")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "g-line-d" }, [
                    _vm._v(_vm._s(_setup.claim().country)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "g-line" }, [
                  _c("div", { staticClass: "g-line-t" }, [_vm._v("Отель")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "g-line-d" }, [
                    _vm._v(_vm._s(_setup.claim().hotel)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "g-line" }, [
                  _c("div", { staticClass: "g-line-t" }, [_vm._v("Туристы")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "g-line-d" },
                    _vm._l(_setup.claim().tourists, function (t) {
                      return _c("div", [
                        _vm._v(
                          "\n                            " +
                            _vm._s(t) +
                            "\n                        "
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "g" }, [
                _c("div", { staticClass: "g-h" }, [
                  _vm._v(
                    "\n                    Выплата\n                    \n                "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "g-line" }, [
                  _c("div", { staticClass: "g-line-t" }, [_vm._v("Документы")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "g-line-d links" },
                    _vm._l(_setup.payout().documentLinks, function (li) {
                      return _c(
                        "a",
                        {
                          staticClass: "link",
                          attrs: { href: _setup.doc_link(li) },
                        },
                        [_vm._v(_vm._s(_setup.doc_text(li)))]
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "g-line" }, [
                  _c("div", { staticClass: "g-line-t" }, [
                    _vm._v("Сумма выплаты"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "g-line-d" }, [
                    _vm._v(_vm._s(_setup.payout().payoutSum)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "g-line" }, [
                  _c("div", { staticClass: "g-line-t" }, [
                    _vm._v("Перечислено агенту"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "g-line-d" }, [
                    _vm._v(_vm._s(_setup.payout().agentAmount)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "g-line" }, [
                  _c("div", { staticClass: "g-line-t" }, [
                    _vm._v("Метод выплаты"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "g-line-d" }, [
                    _vm._v(_vm._s(_setup.payout().payoutMethod)),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "g-line" }, [
                  _c("div", { staticClass: "g-line-t" }, [_vm._v("Состояние")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "g-line-d" }, [
                    _vm._v(_vm._s(_setup.payout().statusText)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _setup.isPending()
                ? _c(_setup.Success, [_vm._v("Выплата принята")])
                : _vm._e(),
              _vm._v(" "),
              _setup.isCreated()
                ? _c(_setup.Confirm, {
                    tag: "div",
                    attrs: {
                      payoutId: _setup.payout().id,
                      providerId: _setup.payout().providerId,
                      providerAgentId: _setup.payout().providerAgentId,
                      phone: _setup.account().phone,
                    },
                    on: { refresh: _setup.refresh },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }