var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "icodiv success" }, [
    _c("div", { staticClass: "icodiv-ico" }, [_c(_setup.OkSvg)], 1),
    _vm._v(" "),
    _c("div", { staticClass: "icodiv-text" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }