var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _setup.payoutId
        ? _c(_setup.Payout, {
            key: `${_setup.payoutId}.${_setup.epoch}`,
            attrs: { payoutId: _setup.payoutId },
            on: { refresh: _setup.refresh },
          })
        : _vm._e(),
      _vm._v(" "),
      !_setup.payoutId
        ? _c(_setup.Warn, [
            _vm._v("\n        Неверная ссылка: не задан код выплаты\n    "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }