
export {fetch_save} 

function fetch_save({resolve:solve, reject:ject, wait:w}) {// лексическая функция, не важно где используют
    let ok = solve ? true : false
    let wait = solve || ject ? false : w
    let resolve = solve ? solve : null
    let reject = ject ? ject : null

    return {
        ok, wait, resolve , reject
    }
}
