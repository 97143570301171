var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _setup.testappUse ? _c(_setup.testappRoot, { tag: "div" }) : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "payout" }, [_c("router-view")], 1),
      _vm._v(" "),
      _c("div", { staticClass: "versions" }, [
        _c(
          "a",
          {
            attrs: {
              href: _setup.gitlink,
              title: _setup.gittitle,
              target: "_blank",
            },
          },
          [_vm._v(_vm._s(_setup.ver))]
        ),
        _vm._v(" "),
        _c("span", { attrs: { title: _setup.gate } }, [_vm._v("gate")]),
      ]),
      _vm._v(" "),
      _c(_setup.FooterA),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("header", { staticClass: "header" }, [
      _c("div", { staticClass: "header__wrap container" }, [
        _c("div", { staticClass: "header__logo" }, [
          _c("img", {
            attrs: {
              src: "https://cdn.eto.travel/i/logo-header.svg",
              alt: "ЭТО трэвел",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "header__manager-info manager-info" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }