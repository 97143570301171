<template><div>
        <div v-if="wait()"><Loader /></div>
        <Warn v-if="!wait() && !ok()">{{err()}}</Warn>

        <div v-if="ok()">
            <h1>Выплата по заявке {{payout().claimNum}}</h1>

            <div class="g">
                <div class="g-line">
                    <div class="g-line-t">Период</div>
                    <div class="g-line-d">{{dayjs_dm(claim().dateBeg)}} - {{dayjs_dm(claim().dateEnd)}}</div>
                </div>
                <div class="g-line">
                    <div class="g-line-t">Страна</div>
                    <div class="g-line-d">{{claim().country}}</div>
                </div>
                <div class="g-line">
                    <div class="g-line-t">Отель</div>
                    <div class="g-line-d">{{claim().hotel}}</div>
                </div>
                <div class="g-line">
                    <div class="g-line-t">Туристы</div>
                    <div class="g-line-d">
                        <div v-for="t in claim().tourists">
                            {{t}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="g">
                <div class="g-h">
                    Выплата
                    
                </div>
                <div class="g-line">
                    <div class="g-line-t">Документы</div>
                    <div class="g-line-d links">
                        <a v-for="li in payout().documentLinks" class="link" :href="doc_link(li)">{{doc_text(li)}}</a>
                    </div>
                </div>
                <div class="g-line">
                    <div class="g-line-t">Сумма выплаты</div>
                    <div class="g-line-d">{{payout().payoutSum}}</div>
                </div>
                <div class="g-line">
                    <div class="g-line-t">Перечислено агенту</div>
                    <div class="g-line-d">{{payout().agentAmount}}</div>
                </div>
                <div class="g-line">
                    <div class="g-line-t">Метод выплаты</div>
                    <div class="g-line-d">{{payout().payoutMethod}}</div>
                </div>
                <div class="g-line">
                    <div class="g-line-t">Состояние</div>
                    <div class="g-line-d">{{payout().statusText}}</div>
                </div>
            </div>

            <Success v-if="isPending()">Выплата принята</Success>

            <div v-if="isCreated()" :is="Confirm" 
                :payoutId="payout().id" 
                :providerId="payout().providerId" 
                :providerAgentId="payout().providerAgentId"
                :phone="account().phone" @refresh="refresh"></div>
        </div>



</div></template>

<style src="./Payout.scss"></style>

<script setup>
import {ref, unref, watch, computed} from 'vue'    
import {payoutByUuid, payoutClaim, payoutAccount, PLAY_PAYOUT} from '@/actions.js'
import Confirm from './Confirm.vue'
import {fetch_save} from '@/util.js'
import Success from '@/comp/Success.vue'
import Loader from '@/comp/Loader/Loader.vue'
import Warn from '@/comp/Warn.vue'
import dayjs from 'dayjs'

const doc_link = (li) => `${PLAY_PAYOUT}/doc/${li.link}`
const doc_text = (li) => li.documentName.replace(/\.\w+$/, '')

const dayjs_dm = (d) => dayjs(d).format("DD.MM.YYYY")

const props = defineProps({
    payoutId: String, 
})
const emits = defineEmits([ 'refresh' ])


const payob =ref({// считаем что будет ровно одно заполнение payob. Поэтому wait изначально false
    payout: fetch_save({wait: true}),
    claim: fetch_save({wait: false}),
    account: fetch_save({wait: false}),
})

const refresh = () => {// если выплата подтверждена - обновляем
    emits('refresh')
}

const payout = () => payob.value.payout.resolve
const claim = () => payob.value.claim.resolve
const account = () => payob.value.account.resolve
const ok = () => {
    let find = Object.values(payob.value).find( fetch => fetch.ok!==true )
    return find ? false : true
}

const isCreated = () => {
    return payout().status==='Created'
}
const isPending = () => {
    return payout().status==='Pending'
}

const wait = () => {
    let find = Object.values(payob.value).find( fetch => fetch.wait===true )
    return find ? true : false
}
const err = () => {
    let ret = 'ошибка'
    let rejs=Object.values(payob.value).map( ({reject}) => reject ).filter( x => x ? true : false )
    let [rej, ] = rejs
    if (rej) {
        ret = String( rej )
    }

    return ret
} 


const load = () => {
//console.log('loa', props.payoutId)
    const { payoutId } = props

    payoutByUuid( {payoutId} ).then( resolve =>{

        payob.value.payout = fetch_save({resolve})
        payob.value.claim = fetch_save({wait: true})
        payob.value.account = fetch_save({wait: true})

        let {providerId, claimNum, providerAgentId} = resolve
        payoutClaim( {providerId, claimNum, providerAgentId} ).then( resolve => {
            payob.value.claim = fetch_save({resolve})
        }, reject=>{
            payob.value.claim = fetch_save({reject})
        })

        payoutAccount( {providerId,  providerAgentId} ).then(  resolve => {
            payob.value.account = fetch_save({resolve})
        }, reject => {
            payob.value.account = fetch_save({reject})
        });


    }, reject=> {
        payob.value.payout = fetch_save({reject})
    })
}

load()

</script>