
const PLAY_SERENITI_PROD = 'https://play.eto.travel'
const PLAY_SERENITI_DEV = 'https://play.sereniti.ru'
const PLAY_PAYOUT_DEV = 'https://payout-debug1.oblako.travel/api'
const PLAY_PAYOUT_PROD = 'https://payout.oblako.travel/api'
export const CRUISE_SVG_BASE = 'https://eto.travel/cruise-svg'

const set_vars = ({force_prod}) => {
    let PLAY_SERENITI = PLAY_SERENITI_DEV
    let PLAY_PAYOUT = PLAY_PAYOUT_DEV
    if (force_prod) {// чтобы на тесте использовать prod
        PLAY_SERENITI = PLAY_SERENITI_PROD
        PLAY_PAYOUT = PLAY_PAYOUT_PROD
    } else {// обычный алгоритм: по домену
        let isProd = /\beto\.travel$/.test( window.location.host )
        PLAY_SERENITI  = isProd ? PLAY_SERENITI_PROD : PLAY_SERENITI_DEV
        PLAY_PAYOUT = isProd ? PLAY_PAYOUT_PROD : PLAY_PAYOUT_DEV
    }
    return {PLAY_SERENITI, PLAY_PAYOUT}
}
export const { PLAY_SERENITI, PLAY_PAYOUT } = set_vars( ETOBUILD ) // ETOBUILD опередено в webpack


export const SORT_DEFAULT='date'
export const LOCALSTOR_TOURIST_KEY = 'cruise.tourist'
export const LOCALSTOR_CABINS_KEY = 'cruise.cabins'
export const LOCALSTOR_BOOK_KEY = 'cruise.book'
export const MAX_CABINS = 3 // максимальное количество кабинок

export const cruises_sort_par = (datain) => {
    const data=datain || {}
    let par=[]
    par.push( extract_par_str(data, 'sort', SORT_DEFAULT) )
    par.push( extract_par_str(data, 'asc', '1') )
    par.push( extract_par_str(data, 'limit', '10') )
    return par
}


export const cruises_page = (datain)  => {
    let [[k,v]]=cruises_page_par(datain)
    return parseInt( v, 10 )
}
export const cruises_limit = (datain)  => {
    let [,v]=cruises_sort_par(datain).find( ([k,]) => k==='limit' )
    return parseInt( v, 10 )
}

export const cruise_next_pageob = okpage =>  {
    const page = okpage === null ? 1 : 1 + okpage // получить первую или следующую страничку
    return { page }
}
export const cruises_page_par = (datain) => {
    const data=datain || {}
    let par=[]
    par.push( extract_par_str(data, 'page', '1') )
    return par
}
export const cruises_sort_key = (datain) => {
  return cruises_sort_par(datain).map( kv_arr => kv_arr.join(':')  ).join(';')
}

export const shipOne=(datain)=>{
    const data=datain || {}
    let par=[]
    par.push( extract_par_str(data, 'id') )
    const parout = Object.fromEntries( par )
    return request_play( 'cruise-ship', parout, {} ).then( all => {
        if ( _get(all, [0,'id']) &&  _get(all, [0,'content', 'id']) ) {
            let [{
                id, name, content,
                img, // главное изображение корабля из нашего редактора
                url, type, decks, capacity, 
                svg, // svg на res.sereniti.ru
                construction_year, renovation_year, length, width, draught, text, 
                captain_name, cruise_director_name, restaurant_director_name,
                captain_img, cruise_director_img, restaurant_director_img,
                schema_img, schema_svg, 
            }] = all
            return {
                id, name, content,
                img, // главное изображение корабля из нашего редактора
                url, type, decks, capacity, 
                svg, // svg на res.sereniti.ru
                construction_year, renovation_year, length, width, draught, text, 
                captain_name, cruise_director_name, restaurant_director_name,
                captain_img, cruise_director_img, restaurant_director_img,
                schema_img, schema_svg, 
            }
        }else{
            console.error('backend-format')
            return Promise.reject('неожиданный ответ')
        }
    } )
}

export const cruiseOne=(datain)=>{
    const data=datain || {}
    let par=[]
    par.push( extract_par_str(data, 'cruiseIdFull') )
    par.push( extract_par_str(data, 'key') )
    const parout = Object.fromEntries( par )
    return request_play( 'cruise-details', parout, {} ).then( all => {
        if ( 
            _get(all, 'content.prices') 
            && _get(all, 'content.cabins') 
            && _get(all, 'cruise_content.cruise_id') 
            && _get(all, 'ship') 
        ) {
            let { prices, cabins, freePlacesCount } = all.content
            let cruise = all.cruise_content
            let ship = all.ship
            return { prices, cabins, freePlacesCount, cruise, ship }
        }else{
            console.error('backend-format')
            return Promise.reject('неожиданный ответ')
        }
    } )
}

export const findIdByDprov = (datain)=>{
    const data=datain || {}
    let par=[]
    par.push( extract_par_str(data, 'strId') )
    const parout = Object.fromEntries( par )
    return request_play( 'cruise-findid', parout, {} ).then( all => {
        if ( 
            _get(all, 'cruiseIdFull') 
            || _get(all, 'message') 
        ) {
            let { cruiseIdFull, message  } = all
            return { cruiseIdFull, message  }
        }else{
            console.error('backend-format')
            return Promise.reject('неожиданный ответ')
        }
    } )
}

export const book_check = (data) => {
    return book( {...data, complete: 0 } )
}

export const book = (datain) => {
    const data=datain || {}
    const session_id = getPHPSESSID()
    const offer_id = data[ 'cruiseIdFull' ] || ''
    const offer = data[ 'offer' ] || null
    const key = data[ 'key' ] || ''
    const complete = "complete" in data ? data.complete : 1 // бронируем
    const passengers = data.passengers
    const comment = data.comment || ''

    const parout = { session_id, offer_id, complete, passengers, key, comment, offer }

    return request_play( 'cruise-book', parout, { isPost: true, } ).then( (all)=>{
        let { message, calc_prices, claim_id, claim_num    }=all
        if (!message) {
            console.error('backend-format')
            return Promise.reject('неожиданный ответ')
        }
        return { message, calc_prices, claim_id, claim_num  }
    } )
}

export const cruises_list_par = data => {
    let par=[]
    par.push( extract_par_str(data, 'ship') )
    par.push( extract_par_str(data, 'startCity') )
    par.push( extract_par_str(data, 'dateStartFrom') )
    par.push( extract_par_str(data, 'dateStartTo') )
    par.push( extract_par_str(data, 'nightsMin') )
    par.push( extract_par_str(data, 'nightsMax') )
    par.push( extract_par_str(data, 'withPlaces') ) // искать только круизы еще не распроданные
    par.push( extract_par_str(data, 'routeCities') ) // города по маршруту
    par.push( extract_par_str(data, 'popularRoutes') ) // популярные маршруты
    par.push( extract_par_str(data, 'river') ) // реки
    par.push( extract_par_str(data, 'cruise_id') ) // реки
    par.push( extract_par_str(data, 'type') ) // тип круиза
    return par
}

export const cruise_retq_par =  data => {
    let par=[]
    par = par.concat( cruises_list_par( data ) )
//    par= par.concat( cruises_sort_par( data ) )
//    par = par.filter( ([k,v]) => v ? true : false)
    return par
}

export const extract_retlist_query = str => {
    let vv = str.split('.')
    let base = cruise_retq_par({})
    let entr=base.map( ([k,], ind) => {
        let v = ind in vv ? vv[ind] : ''
        return [k,v]
    } )
    let ret = Object.fromEntries(entr)
    return ret
}

export const cruises=(datain)=>{
/* https://play.sereniti.ru/cruise/properties?
    ship=123
    startCity=1188
    &dateStartFrom=2023-05-08&
    dateStartTo=2023-05-31&
    nightsMin=2&
    nightsMax=6    */
    const data=datain || {}
    let par=[]
    par = par.concat( cruises_list_par( data ) ) 
    par= par.concat( cruises_sort_par( data ) ) 
    par= par.concat( cruises_page_par( data ) )
//console.log('cruises', par)    
    par = par.filter( ([k,v]) => v || k === 'type' ? true : false)
    const parout = Object.fromEntries( par )
    return request_play( 'cruise-search', parout, {} ).then( (all)=>{
        let { count, page, pages, data:list  }=all
        if (list && 'object' === typeof list ) {
            list = Object.values( list )
        }
        if (!Array.isArray(list)) {
            console.error('backend-format')
            return Promise.reject('неожиданный ответ')
        }
        return { count, page, pages, list  }
    } )
}


const id2str = list => {
    return list.map( ob => {
        ob.id = ob.id.toString()
        return ob
    })
}

export const properties=(datain)=>{
/* https://play.sereniti.ru/cruise/properties?
    ship=123
    startCity=1188
    &dateStartFrom=2023-05-08&
    dateStartTo=2023-05-31&
    nightsMin=2&
    nightsMax=6    */
    const data=datain || {}
    let par=[]
    par = par.concat( cruises_list_par( data ) ) 
    par = par.filter( ([k,v]) => v || k === 'type' ? true : false)
    const parout = Object.fromEntries( par )
    return request_play( 'cruise-properties', parout, {} ).then( (all)=>{
        let { startCities, ships, dates, nights, rivers, popularRoutes, routeCities }=all
        if (!startCities) {
            console.error('backend-format', )
            return Promise.reject('неожиданные ответ')
        }
        startCities = id2str( startCities )
        ships = id2str( ships )
        rivers = id2str( rivers )
        popularRoutes = id2str( popularRoutes )
        routeCities = id2str( routeCities )
        return { startCities, ships, dates, nights, rivers, popularRoutes, routeCities }
    } )
}


export const propbyhand=(datain)=>{
/* https://play.sereniti.ru/cruise/properties?
    ship=123
    startCity=1188
    &dateStartFrom=2023-05-08&
    dateStartTo=2023-05-31&
    nightsMin=2&
    nightsMax=6    */
    const data=datain || {}
    let par=[]
    const parout = Object.fromEntries( par )
    return request_play( 'cruise-propbyhand', parout, {} ).then( (all)=>{
        let { towns, ships, opers, nights_min, nights_max, rivers, date_min, date_max }=all
        if (!towns) {
            console.error('backend-format', )
            return Promise.reject('неожиданные ответ')
        }
        towns = id2str( towns )
        ships = id2str( ships )
        opers = id2str( opers )

        return { towns, ships, nights_min, nights_max, rivers, date_min, date_max, opers }
    } )
}


export const auth_login = (datain)=> {
    let {login,password} = datain || {}
    const parout = {login,password}
    return request_play( 'login', parout, {isPost: true} ).then( all => {
        let {session} = all
        if (!session) {
            console.error('backend-format', )
            return Promise.reject('неожиданные ответ')
        }
        return { login, session }
    })
}
export const auth_logout = (datain)=> {
    let {session} = datain || {}
    const parout = { session}
    return request_play( 'logout', parout, {isPost: true} ).then( all => {
        return { ok: true }
    })
}


function extract_par_id(data, key) {
    let ret=_get(data, [key,'id'])
    ret=ret ? ret.toString() : ''
    return [key,ret]
}
function extract_par_str(data,key, def='') {
    let ret=_get(data, [key])
//console.log('extract_par_str', data, key, ret, typeof(ret))  
    ret=(["string", "number"].includes(typeof(ret))) ? ret.toString() : def
    return [key,ret]
}
//export  extract_par_str


export const fetch_svg = (url) => {
    const req={
        method: 'GET',
        redirect: 'error',
        credentials: 'omit',
    }
    return fetch( url, req).then( o => o.text() ).then( proc, proc)

    function proc(x) {
        let ret
        if ( "string" === typeof x && x.substring(0, 1024).match(/<svg/i) ) {
            ret = x
        } else {
console.error('fetch_svg', x)            
            ret = Promise.reject('не удалось')
        }
        return ret
    }
}

export const request_play=(action, datain, features) => {
  const {stopme, isPost, requireSession, payout, postEmpty}=features || {}
  let baseurl=`${PLAY_SERENITI}/${action}`
  if (payout) {
    baseurl=`${PLAY_PAYOUT}/${action}`
  }
  let g,p, credentials= requireSession ? 'include' : 'omit'
  let data=datain || {}
  if ( isPost ) {
    g={}
    if (data.key) {
        g.key = data.key
    }
    p={...data}
    if (postEmpty) {
        g = {...data}
        p = null
    }
  } else {
    g={...data, ...nc_eq() }
    p=null
  }
  const baseurlob=new URL(baseurl)
  Object.keys(g).forEach(key => baseurlob.searchParams.set(key, g[key]))
  const body= p ? JSON.stringify(p) : undefined
  const method= isPost ? 'POST' : 'GET'
  let headers={}
  const signal= stopme ? stopme : undefined
  if (p) {
    headers['Content-Type']='application/json'
  }
  let {headers: dopHeaders} = features
//console.log('headers', headers, 'dopHeaders', dopHeaders)  
  if (dopHeaders) {
    headers = { ...headers, ...dopHeaders  }
  }
  const req={
    method,
    cache: 'no-store',
    headers,
    body,
    referrerPolicy: 'origin',
    redirect: 'error',
    signal,
    credentials,
  }

  return fetch( 
    baseurlob.toString(), req 
  ).then( 
    o => o.json() 
  ).then( 
    ajax_api_return, ajax_api_return 
  )

  function ajax_api_return(x){
    let ret
    if (isObj(x) && (x.success || (false === _get(x,'data.success')) ) ) {
      ret=x.data || {};// возвращаем данные. Если их нету - то пустой объект
    } else {
      if (isObj(x) && 'AbortError'==x.name ) {// в скрипте выполнили abort. Нормальная ситуация
        ret=Promise.reject(x) // прокидываем выше чтобы обработать при необходимости
      }else{// сюда упадет не json в том числе
        console.error('request_play', x);
        const message= isObj(x) && x.message ? x.message : 'не удалось'
        ret=Promise.reject(message);
      }
    }
    return ret;
  }

  function isObj(x) {
    return x && 'object'==typeof(x) ? true : false;
  }

}


/**
 * Gets the value at `path` of `object`.
 * @param {Object} object
 * @param {string|Array} path
 * @returns {*} value if exists else undefined
 */
export const _get = (object, path)=> {
    if (typeof path === "string") path = path.split(".").filter(key => key.length)
    return path.reduce((dive, key) => dive && dive[key], object)
}

export const intersect = (a, b) => {
  var setA = new Set(a);
  var setB = new Set(b);
  var intersection = new Set([...setA].filter(x => setB.has(x)));
  return Array.from(intersection);
}


export const nc_eq = () => { // чтобы подавить кэширование, либо перезапись одинаковую query 5-6 символов
    let a=Math.random().toString().substr(2,8)
    let _= parseInt(a,10).toString(36)
    return { _ }
}


export const getCookie = (name) => {
    function escape(s) { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); }
    var match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? match[1] : null;
}

export const getPHPSESSID = ()=>{
    let sessid = ''
    if (location.hostname.match(/^[\d.]+$/)) {// отладка через IP адрес, вида 192.168.17.20
        sessid = auth_store_load().session
    } else {
        sessid = getCookie('PHPSESSID')
    }
    return sessid
}

export const random_str = () => {
    let a = new Uint32Array(2)
    window.crypto.getRandomValues(a)
    let ret = [...a].map( x => x.toString(36) ).join('')
    return ret
}

export const findLast = (ar, func) => { 
    let i=ar.length-1
    while (i>=0 && !func(ar[i]) ) {
        i--
    }
    return i>=0 ? ar[i] : undefined
}


/**********************************
* auth lib - пока прототип, работает в тесте
**********************************/

export const AUTH_LSTOR_KEY = 'etosess'

export  const auth_store_load = () => {
    let loadob = {login: '', session: ''}
    try {
        const str = localStorage.getItem( AUTH_LSTOR_KEY )
        if (str) {
            let x = JSON.parse( str )
            let { login, session} = x
            if (
              login && 'string' == typeof(login) 
              && session && 'string' == typeof(session) 
            ) {
              loadob = { login, session }
            }
        }
    } catch (e) {
        console.error('auth_store_load', e)
    }
    return loadob
}

export  const auth_store_save = ( {login,session} ) => {
    let str = JSON.stringify( {login,session} )
    localStorage.setItem( AUTH_LSTOR_KEY, str )
}

export const auth_store_del = () => {
    localStorage.removeItem( AUTH_LSTOR_KEY ) 
}

const request_payout = ( action, datain, featuresIn={} )=> {
    let {headers, isPost, postEmpty} = featuresIn
    headers = headers || {}
    headers = { Accept: 'application/json', ...headers}
    let features= { payout: true, headers, isPost, postEmpty}

    return request_play(action, datain, features)
}

export const payoutByUuid=(datain)=>{
    const data=datain || {}
    let {payoutId} = data
    const parout = {payoutId}
    return request_payout( 'Payout', parout ).then( (all)=>{
        let { 
            providerId, providerCompanyId, id, providerAgentId, claimNum, createDate, 
            status, statusText, documentLinks, statusHistory, 
            comment, recipientType, methodName, payGateName, payoutSum, agentAmount, payoutMethod
        }=all
        if (!providerId) {
            console.error('backend-format', )
            return Promise.reject('неожиданный ответ')
        }
        return { 
            providerId, providerCompanyId, id, providerAgentId, claimNum, createDate, 
            status, statusText, documentLinks, statusHistory, 
            comment, recipientType, methodName, payGateName, payoutSum, agentAmount, payoutMethod
        }
    } )
}

export const payoutClaim=(datain)=>{
/*etch('https://payout-debug1.oblako.travel/api/claims?providerId=98544a82-e474-4dd3-a95a-81892c02e5d0&claim=1039036', {headers: {
  "PayoutSessionId": "17019",
}})*/    
    const data=datain || {}
    let {providerId, claimNum:claim, providerAgentId:PayoutSessionId} = data
    const parout = {providerId, claim}
    const headers = {PayoutSessionId}
    return request_payout( 'claims', parout, {headers} ).then( (all)=>{
        let { // мы дважды перечисляем большой список. Зато код прост
            providerCompanyId, claimNum, payoutId,

            createDate, country, dateBeg, dateEnd, tourists, hotel, tourOperator, 

            paymentStatus, 
            payoutStatus, 
            currency, paidByTourist, paymentCommission, agentPrice, agentReward, payoutAvailabilityDate,

            link,
        }=all
        if (!claimNum) {
            console.error('backend-format', )
            return Promise.reject('неожиданный ответ')
        }
        return { 
            providerCompanyId, claimNum, payoutId,

            createDate, country, dateBeg, dateEnd, tourists, hotel, tourOperator, 

            paymentStatus, 
            payoutStatus, 
            currency, paidByTourist, paymentCommission, agentPrice, agentReward, payoutAvailabilityDate,

            link,
        }
    })
}


export const payoutAccount =  (datain) => {
    const data=datain || {}
    let {providerId, providerAgentId:PayoutSessionId} = data
    const parout = {providerId}
    const headers = {PayoutSessionId}
    return request_payout( 'Account', parout, {headers} ).then( all => {
        let {
            agencyName, agencyType, email, inn, isSelfEmployed, name, phone, providerAgentId, providerId
        } = all
        if (!phone) {
            console.error('backend-format', )
            return Promise.reject('неожиданный ответ')
        }
        return {
            agencyName, agencyType, email, inn, isSelfEmployed, name, phone, providerAgentId, providerId
        }
    });
}


export const payoutSendCode =  (datain) => {// sendCode?payoutId={payoutId}
    const data=datain || {}
    let {payoutId, providerAgentId:PayoutSessionId} = data
    const parout = {payoutId}
    const headers = {PayoutSessionId}
    return request_payout( 'Payout/sendCode', parout, {headers, isPost: true, postEmpty: true}).then( all => {
        let {
            ticket, expires, attempt
        } = all
        if (!ticket) {
            console.error('backend-format', )
            return Promise.reject('неожиданный ответ')
        }
        return {
            ticket, expires, attempt            
        }
    })
}

export const payoutConfirm =  (datain) => {// sendCode?payoutId={payoutId}
    const data=datain || {}
    let {providerId, ticket, code} = data
    const parout = {providerId, ticket, code}
    return request_payout( 'Payout/confirm', parout, {isPost: true}).then( all => {
        let {
            message, secondsToExpire, attempt, success
        } = all
        if (!Number.isInteger(attempt)) {
            console.error('backend-format', )
            return Promise.reject('неожиданный ответ')
        }
        return {
            message, secondsToExpire, attempt, success
        }
    })
}



