<template>
    <div>
        <div>ByUuidExPage.veu</div>

        <div v-if="wait()">wait...</div>
        <div v-if="!wait() && !ok()">{{err()}}</div>

        <div v-if="ok()">
            <div>claimNum={{payout().claimNum}}</div>
            <div>status={{payout().status}}</div>
            <div>ProviderId={{payout().providerId}}</div>
            <div>ProviderAgentId={{payout().providerAgentId}}</div>

            <div>Заявка</div>
            <div>туристы</div>
            <div v-for="t in claim().tourists">
                {{t}}
            </div>
            <div>Телефон {{account().phone}}</div>

            <div>
                <button @click="cli_send">отправить</button>
            </div>

            <div v-if="payob.send.ok && payob.send.resolve.ticket">смс код
                <input
                  type="number"
                  class="confirm-form__input"
                  name="sms-code"
                  :value="code"
                  @input="set_code"
                  :maxlength="maxlength"
                  required>

            </div>
             
        </div>
    </div>
</template>

<script setup>
import {ref, unref, watch, computed} from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import {payoutByUuid, payoutClaim, payoutAccount, payoutSendCode, payoutConfirm} from '@/actions.js'

const empty = () => {
    return {ok:null, wait:true, resolve:null, reject: null}
}

const payob =ref({
    payout: {ok:null, wait:true, resolve:null, reject: null},
    claim: {ok:null, wait:true, resolve:null, reject: null},
    account: {ok:null, wait:true, resolve:null, reject: null},
    send: {ok:null, wait:false, resolve:null, reject: null},
})

const cli_send = () => {
    if (payob.value.send.wait) {
        return
    }
    payob.value.send.wait = true 
    let {id:payoutId, providerAgentId}  = payout()
    
    payoutSendCode( { payoutId, providerAgentId } ).then( resolve => {
        payob.value.send = { ok:true, wait:false, resolve, reject: null  }
    }, reject => {
        payob.value.send = { ok:true, wait:false, resolve:null, reject  }
    })
}

const maxlength=4
const code = ref('')
const set_code = (event) => {
    let thisValue = event.target.value
    if (thisValue.length > maxlength) {
        thisValue = thisValue.slice(0, maxlength)
    }
    code.value = thisValue
    if (thisValue.length === maxlength) {
        confirm()
    }
}
const confirm = () => {
    let {providerId } = payout()
    let {ticket} = payob.value.send.resolve
    let codeval = unref( code )
    payoutConfirm( { providerId, ticket, code: codeval   } )
}

const payout = () => payob.value.payout.resolve
const claim = () => payob.value.claim.resolve
const account = () => payob.value.account.resolve
const ok = () => {
    let p = payob.value.payout
    let c = payob.value.claim
    let a = payob.value.account
    return !p.wait && p.ok && !c.wait && c.ok && !a.wait && a.ok
}
const wait = () => {
    let p = payob.value.payout
    let c = payob.value.claim
    let a = payob.value.account
    return p.wait || c.wait || a.wait
}
const err = () => {
    let p = payob.value.payout
    let c = payob.value.claim
    let a = payob.value.account
    let ret = 'ошибка'
    let rejs=[p.reject, c.reject, a.reject].filter( x => x ? true : false )
    let [rej, ] = rejs
    if (rej) {
        ret = String( rej )
    }

    return ret;
} 

window.payoutByUuid = payoutByUuid

const route = useRoute()

watch( 
    [   
        () => route.query.payoutId,
    ],
    () => {
        const { payoutId } = route.query
        console.log('changed', payoutId)
        payob.value.payout.wait = true
        payob.value.claim.wait = true
        payob.value.account.wait = true
        payoutByUuid( {payoutId} ).then( resolve =>{
            payob.value.payout = { ok:true, wait:false, resolve, reject: null  }
            let {providerId, claimNum, providerAgentId} = resolve
            payoutClaim( {providerId, claimNum, providerAgentId} ).then( resolve => {
                payob.value.claim = { ok:true, wait:false, resolve, reject: null  }
            }, reject=>{
                payob.value.claim = { ok:false, wait:false, resolve: null, reject  }
            })

            payoutAccount( {providerId,  providerAgentId} ).then(  resolve => {
                payob.value.account = { ok:true, wait:false, resolve, reject: null  }
            }, reject => {
                payob.value.account = { ok:false, wait:false, resolve: null, reject  }
            });


        }, reject=> {
            payob.value.payout = { ok:false, wait:false, resolve: null, reject  }
            payob.value.claim = { ok:false, wait:false, resolve: null, reject: null }
            payob.value.account = { ok:false, wait:false, resolve: null, reject: null }
        }  )
    },
    { immediate: true }
)
</script>