<template>
<div>

  <header class="header">
    <div class="header__wrap container">
      <div class="header__logo">
          <img src="https://cdn.eto.travel/i/logo-header.svg" alt="ЭТО трэвел" />
      </div>
      <div class="header__manager-info manager-info">
      </div>
    </div>
  </header>

  <div v-if="testappUse" :is="testappRoot"></div>


  <div class="payout">
    <router-view />
  </div>
  <div class="versions">
    <a :href="gitlink" :title="gittitle" target="_blank">{{ver}}</a> <span :title="gate">gate</span>
  </div>


  <FooterA />
</div>
</template>

<style src="@/assets/styles/main.scss" lang="scss"></style>

<style scoped lang="scss">
  .versions {
    text-align: right; 
    color:#999; font-size: 10px;
    a {
      color:#999; 
    }
  }
</style>

<script setup>
import {testappUse, testappRoutes, testappRoot   } from 'TESTAPP/testapp.js'
import { PLAY_PAYOUT } from '@/actions.js'
import FooterA from '@/comp/Footer.vue'

const {date, commit, branch} = ETOBUILD
const gitlink = `https://git.eto.travel/amendelev/payout2/commits/branch/${branch}/search?q=${commit}`
const gittitle = `${date}; ${branch}`
const ver = commit.substr(0,7)
const gate = PLAY_PAYOUT

</script>
